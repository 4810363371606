<template>
    <div class="home">
        <section class="page-cover">
            <div class="bg-image">
                <div class="background-image-wrapper" :style="{'background-image': 'url(' + `/cover.jpg` + ')'}" />
            </div>
            <div class="content-wrapper pt-12 lg:flex lg:justify-end px-4 md:px-14 lg:px-24">
                <div class="content">
                    <h1 class="page-title">The Recruitment specialist for <span class="text-primary">Early Years</span> Professionals</h1>
                    <p class="text-xl mt-4">Recruitment Specialists for Early Years Staff and Education Sectors. Whether you are seeking outstanding Teaching or Early Years Staff or searching for your next career move we can help you.</p>
                    <div class="mt-6">
                        <nuxt-link to="/recruitment"><button class="btn btn-secondary">Register</button></nuxt-link>
                        <nuxt-link to="/jobs" class="ml-4"><button class="btn btn-primary">Find a Job</button></nuxt-link>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="px-4 lg:px-12 md:container md:mx-auto py-16 lg:py-32">
                <div class="grid lg:grid-cols-2 gap-4 lg:gap-12 items-center">
                    <div class="image-wrapper">
                        <div class="background-image-wrapper" :style="{'background-image': 'url(' + `/images/about.jpg` + ')'}" />
                    </div>
                    <div>
                        <h3 class="text-primary text-3xl font-semibold">About Early Years</h3>
                        <p class="mt-6">Early Years recruitment is an extraordinary Recruitment Company who hold a wealth of experience within the Education and Care sectors.</p>
                        <p class="mt-4">Our passion and personal approach sets us aside and makes us a unique Agency. We work closely with our Clients to understand their requirements and to match the right candidate to the right role.</p>
                        <p class="mt-4">We supply Teaching Staff, Nursery Staff and Support Workers on short term, long term and permanent basis to a wide range of Schools, Nurseries and Care Settings across all the Home Counties and London.</p>
                        <div class="mt-8">
                            <nuxt-link to="/about">
                                <button class="btn btn-primary">Learn More</button>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-bg">
            <div class="px-4 lg:px-12 md:container md:mx-auto py-12 lg:py-32">
                <h3 class="text-primary text-3xl font-semibold text-center">Why Us?</h3>
                <p class="mt-6 text-center">At Early Years Recruitment we give job seekers access to a broader range of employment options. For Early Years providers we aim to match individuals seeking employment with their business needs.</p>
                <div class="flex justify-center flex-wrap gap-x-2 gap-y-6 lg:gap-x-16 lg:gap-y-24 mt-16">
                    <Benefits icon="/images/icons/briefcase.svg" title="Access to a larger pool of job opportunities" />
                    <Benefits icon="/images/icons/file-text.svg" title="Expert guidance and support in job search" />
                    <Benefits icon="/images/icons/smile.svg" title="Knowledge and insights related to child day care" />
                    <Benefits icon="/images/icons/users.svg" title="Large networking opportunities " />
                    <Benefits icon="/images/icons/repeat.svg" title="Streamlined recruitment process" />
                </div>
            </div>
        </section>
        <section v-if="testimonialData && testimonialData.length > 0">
            <div class="px-4 lg:px-12 md:container md:mx-auto py-12 lg:py-32">
                <h3 class="text-primary text-3xl font-semibold text-center mb-16">What our clients say about us?</h3>
                <Swiper
                    :speed="2000"
                    :slides-per-view="1"
                    :modules="swiperModules"
                    :autoplay="{
                    delay: 8000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    }"
                    navigation
                    :pagination="{ clickable: true }"
                    :scrollbar="{ draggable: true }"
                >
                    <SwiperSlide v-for="(testimony, index) in testimonialData" :key="index">
                        <Testimonial :review="testimony.Review" :name="testimony.Name" :role="testimony.Role" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    </div>
</template>

<script setup>
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const swiperModules = [Autoplay, Navigation, Pagination];
const { data: testimonialData } = await useAsyncData('testimonialData', () => 
    queryContent('/testimonial').sort({ Order: 1, $numeric: true }).find()
);
useHead({
    title: 'Home'
});
</script>

<style lang="scss" scoped>
.home {
    .page-cover {
        position: relative;
        .bg-image {
            position: relative;
            width: 100%;
            height: calc(100vh - 100px);
        }
        .content-wrapper {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            .content {
                width: 40%;
            }
        }
        @media (max-width: 767px),
            (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
           position: static;
            .bg-image {
            height: 250px;
           }
           .content-wrapper {
            position: static;
            transform: none;
            .content {
                width: 100%;
            }
           }
        }
        @media (max-width: 767px), (min-device-width: 390px) and (max-device-width: 844px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
            .bg-image {
                height: 250px;
           }
           .content-wrapper {
            position: static;
            transform: none;
            .content {
                width: 100%;
            }
           }
        }
    }
    .image-wrapper {
        position: relative;
        height: 400px;
        @media (max-width: 767px), (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
           height: 250px;
        }
    }
}
:deep() {
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      &.swiper-pagination-bullet {
        background: $brand-primary;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      color: $brand-secondary;
      cursor: pointer;
      font-size: 32px;
    }
    @media (max-width: 767px),
      (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      display: none;
    }
  }
}
</style>